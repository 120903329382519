import dayjs from "dayjs";

const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

const GOAL_STATUS_ALL = "ALL";
export const GOAL_STATUS_ACTIVE = "ACTIVE";
const GOAL_STATUS_PENDING = "PENDING";


export const getTeamGoals = async (OrgId: number, GoalStatus?: string) => {
  try {
    var params = null;
    if (GoalStatus) {
       params = {org_id: OrgId.toString(), goal_status: GoalStatus};
    }
    else {
      params = {org_id: OrgId.toString()};
    }

    const response = await fetch(
      `${PUBLIC_API_URL}/goals?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const createTeamGoals = async (
  strategyId: number,
  goalDescription: string,
  goalTargetDate: string | null,
  goalCreatedUserId: number
) => {
  try {
    const goalBody = {
      strategy_id: strategyId,
      goal_description: goalDescription,
      goal_target_date: goalTargetDate,
      goal_created_date: dayjs().format("YYYY-mm-dd"),
      goal_created_user_id: goalCreatedUserId,
      goal_achieved_date: null,
      goal_achieved_user_id: null,
      goal_status: GOAL_STATUS_PENDING,
    };
    const response = await fetch(`${PUBLIC_API_URL}/goals`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(goalBody),
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const updateTeamGoals = async (
  goalId: number,
  goalDescription: string,
  goalTargetDate: string | null,
  goalAchievedDate: string | null,
  goalAchievedUserId: number | null,
  goalStatus: string | null
  ) => {
  try {
    const goalBody = {
      goal_id: goalId,
      goal_description: goalDescription,
      goal_target_date: goalTargetDate,
      goal_achieved_date: goalAchievedDate,
      goal_achieved_user_id: goalAchievedUserId,
      goal_status: goalStatus,
    };
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
        }).toString(),
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(goalBody),
      }
    );
    return response.status;
  } catch (err) {
    console.error(err);
  }
};

export const createTeamGoalsKpi = async (
  goalId: number,
  kpiName: string,
  kpiMeasurement: string | null,
  kpiTargetDate: string | null,
  kpiTargetValue: number | null,
  kpiWeight: number | null,
  kpiCreatedUserId: number
) => {
  try {
    const kpiBody = {
      kpi_name: kpiName,
      kpi_measure_definition: kpiMeasurement,
      kpi_target_date: kpiTargetDate,
      kpi_target_value: kpiTargetValue,
      kpi_weight: kpiWeight,
      kpi_created_date: dayjs().format("YYYY-mm-dd"),
      kpi_created_user_id: kpiCreatedUserId,
      kpi_achieved_date: null,
      kpi_achieved_user_id: null,
    };
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id/kpis?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
        }).toString(),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(kpiBody),
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getTeamGoalsKpi = async (goalId: number, kpiId: number) => {
  try {
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id/kpis/kpi_id?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
          kpi_id: kpiId.toString(),
        }).toString(),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const updateTeamGoalsKpi = async (
  goalId: number,
  kpiId: number,
  kpiName: string,
  kpiMeasurement: string | null,
  kpiTargetDate: string | null,
  kpiTargetValue: number | null,
  kpiWeight: number | null,
  kpiCreatedDate: string,
  kpiCreatedUserId: number,
  kpiAchievedDate: string | null,
  kpiAchievedUserId: number | null
) => {
  const kpiBody = {
    kpi_name: kpiName,
    kpi_measure_definition: kpiMeasurement,
    kpi_target_date: kpiTargetDate,
    kpi_target_value: kpiTargetValue,
    kpi_weight: kpiWeight,
    kpi_created_date: kpiCreatedDate,
    kpi_created_user_id: kpiCreatedUserId,
    kpi_achieved_date: kpiAchievedDate === "" ? null : kpiAchievedDate,
    kpi_achieved_user_id: kpiAchievedUserId,
  };
  try {
    const response = await fetch(
      `${PUBLIC_API_URL}/goals/goal_id/kpis/kpi_id?` +
        new URLSearchParams({
          goal_id: goalId.toString(),
          kpi_id: kpiId.toString(),
        }).toString(),
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(kpiBody),
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
