import React, { useState }  from "react";
import {
  Button,
  Container,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { seedCompany, CompanySeeded } from "../../services/PublicAPI";
//import { createTypeReferenceDirectiveResolutionCache } from "typescript";


const Waitlist = () => {
  const [company, setCompany] = useState<CompanySeeded>();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [buttonMsg, setButtonText] = useState("join the waitlist");
  const [canAdd, setCanAdd] = useState(true);
  const [error, setError] = useState('');

  
  const handleChange   
  = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmail(event.target.value);
    setError(''); // Clear any previous errors on input change
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setButtonText("adding...");
    setIsLoading(true);

    try {
      const company = await seedCompany(email);
      setCompany(company);
      if(company) {
        setButtonText("thank you");
        setCanAdd(false);  
      } else {
        setButtonText("join the waitlist");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setButtonText("join the waitlist");
      setIsLoading(false);
    }

  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <Container sx={{ textAlign: "center" }} maxWidth="md">
      {canAdd && <div className="flex justify-center space-x-2 max-w-92 p-4">
        <TextField className="w-2/4" placeholder="Your work email" type="email" name="Email" 
        value={email} onChange={handleChange} 
        error={!!error} // Set error prop if there's an error message
        helperText={error} // Display error message below the input
        required />
        <Button variant="contained" color="primary" size="large" type="submit" 
        onClick={handleSubmit} disabled={isLoading}>{buttonMsg}</Button>
      </div>}
      {!canAdd && <div className="flex text-center justify-center p-2 mission-statement">
        <h2 className="max-w-2xl leading-normal">Thank you, <strong className='bg-orange-600 text-white'> we'll be in touch soon</strong>.</h2>
      </div>}
    </Container>
  );
};

export default Waitlist;