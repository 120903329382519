import React from "react";
import {
  Button,
  ButtonGroup,
  Grid2,
  Container,
  FormHelperText,
} from "@mui/material";
import TextField from "@mui/material/TextField";

interface LoginPageProps {
  setLogin: () => void;
}

export const LoginPage = ({ setLogin }: LoginPageProps) => {
  const handleNext = () => {
    setLogin();
  };
  return (
    <Container sx={{ textAlign: "center" }} maxWidth="xs">
      <h1 style={{ margin: "24px 0" }}>
        Begin your Tribeux setup with your work email.
      </h1>
      <div>
        <TextField fullWidth placeholder="Email" />
        <FormHelperText sx={{ textAlign: "center" }}>
          We use this email to create your team's instance. We don't spam.
        </FormHelperText>
      </div>
      <Grid2 container sx={{ mt: 3 }} justifyContent="flex-end">
        <ButtonGroup variant="contained">
          <Button color="secondary">CANCEL</Button>
          <Button onClick={handleNext} color="primary">
            NEXT
          </Button>
        </ButtonGroup>
      </Grid2>
    </Container>
  );
};
