import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import {ThumbDownAltOutlined, ThumbUpAltOutlined} from "@mui/icons-material";
import {acceptActionPlanSuggestion, rejectActionPlanSuggestion} from "../../services/ActionPlanSuggestionsPublicAPI";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {StepSuggestionCard} from "./StepSuggestionCard";

interface ActionPlanSuggestionCardProps {
    suggestionInfo: ActionItemSuggestionInfo;
    onAcceptSuggestion: (isAccepted: boolean) => void;
    onAcceptSuggestionStep: (suggestionId: number, isAccepted: boolean) => void;
}

export const ActionPlanSuggestionCard = ({
                                             suggestionInfo,
                                             onAcceptSuggestion,
                                             onAcceptSuggestionStep,
                                         }: ActionPlanSuggestionCardProps) => {

    function handleRejectSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();

        rejectActionPlanSuggestion(suggestionInfo.user_action_plan_suggestion_id).then(() => {
            onAcceptSuggestion(false);
        }).catch((e) => {
            console.error("Failed to reject suggestion: " + e);
        });
    }

    function handleAcceptSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();

        acceptActionPlanSuggestion(suggestionInfo.user_action_plan_suggestion_id).then(() => {
            onAcceptSuggestion(true);
        }).catch((e) => {
            console.error("Failed to accept suggestion: " + e);
        });
    }

    const suggestionContent = (
        <Table sx={{tableLayout: "auto"}}>
            <TableBody>
                <TableRow sx={{
                    // Removes the bottom border of the last table row
                    "&:last-child td, &:last-child th": {
                        borderBottom: "none",
                    },
                }}>
                    <TableCell size="small" width="5%">
                    </TableCell>
                    <TableCell align="left" sx={{
                        color: "info.main", // Use secondary color for text
                    }}>
                        {suggestionInfo.user_action_plan_suggestion_goal}
                    </TableCell>
                    <TableCell align={"right"}>
                        <Stack direction={"row"} justifyContent="flex-end">
                            <IconButton size="small" color={"primary"}
                                        onClick={handleAcceptSuggestion}>
                                <ThumbUpAltOutlined fontSize="small"/>
                            </IconButton>
                            <IconButton size="small" color={"primary"}
                                        onClick={handleRejectSuggestion}>
                                <ThumbDownAltOutlined fontSize="small"/>
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const suggestionSteps = (
        <Stack spacing={2}>
            <Table>
                <TableBody>
                    {(suggestionInfo.user_action_plan_step_suggestions || []).map((itemStep) => (
                        <StepSuggestionCard
                            stepSuggestionInfo={itemStep}
                            onAcceptSuggestionStep={onAcceptSuggestionStep}
                            key={itemStep.user_action_plan_step_suggestion_id}
                        />
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="panel-header">
                {suggestionContent}
            </AccordionSummary>
            <AccordionDetails>
                {suggestionSteps}
            </AccordionDetails>
        </Accordion>)
};
