import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';

import { PathConstants } from '../../utils/nav';

import { PageHeader } from '../_PageHeader';
import HighlightedCard from '../_HighlightedCard';
import StatCardX, { StatCardXProps } from './StatCardX';



const engagementDataX: StatCardXProps[] = [
  { title: 'Feedback received',
    value: '258',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: 8%',
    trend: 'neutral',
    data: [ 7, 12, 8, 6, 11, 5, 6, 12, 10, 5, 6, 9, 9, 12, 8, 11, 9, 12, 8, 6, 7, 12, 9, 8, 4, 7, 9, 7, 11, 12 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    }, { title: 'Feedback given',
    value: '252',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: -8%',
    trend: 'neutral',
    data: [ 7, 8, 7, 11, 10, 5, 4, 9, 7, 11, 12, 11, 4, 7, 4, 10, 8, 11, 12, 12, 9, 10, 11, 9, 4, 6, 5, 10, 6, 12 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    }, 
];
const ratingDataX: StatCardXProps[] = [
  { title: 'Skills',
    value: '64%',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: 20%',
    trend: 'up',
    data: [ 5, 5, 4, 3, 2, 4, 3, 4, 1, 5, 5, 4, 2, 2, 5, 1, 3, 5, 2, 2, 1, 4, 3, 2, 2, 3, 4, 4, 4, 4 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    }, { title: 'Competencies',
    value: '53%',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: -12%',
    trend: 'down',
    data: [ 3, 2, 2, 3, 5, 5, 1, 1, 1, 2, 3, 1, 3, 2, 2, 1, 4, 3, 5, 3, 1, 5, 4, 3, 3, 1, 3, 3, 3, 2 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    }, { title: 'Impact percieved',
    value: '62%',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: 44%',
    trend: 'up',
    data: [ 5, 3, 4, 1, 5, 2, 5, 2, 5, 1, 5, 1, 3, 5, 1, 2, 3, 2, 2, 1, 2, 5, 4, 2, 2, 2, 5, 5, 4, 5 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    }, { title: 'Action plan success',
    value: '56%',
    interval: 'Last 30 weeks',
    trend_value: 'last month trend: -33%',
    trend: 'down',
    data: [ 2, 2, 1, 1, 4, 1, 5, 3, 4, 4, 1, 3, 4, 5, 5, 1, 1, 3, 1, 4, 4, 5, 2, 5, 4, 2, 1, 1, 5, 1 ],
    x_data: [ '5/20/2024', '5/27/2024', '6/3/2024', '6/10/2024', '6/17/2024', '6/24/2024', '7/1/2024', '7/8/2024', '7/15/2024', '7/22/2024', '7/29/2024', '8/5/2024', '8/12/2024', '8/19/2024', '8/26/2024', '9/2/2024', '9/9/2024', '9/16/2024', '9/23/2024', '9/30/2024', '10/7/2024', '10/14/2024', '10/21/2024', '10/28/2024', '11/4/2024', '11/11/2024', '11/18/2024', '11/25/2024', '12/2/2024', '12/9/2024' ],
    },
];

export default function MainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <PageHeader
        title="Feedback frequency"
        subtitle=""
      />
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {engagementDataX.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCardX {...card} />
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <HighlightedCard 
            title="Explore your feedback" 
            details="Uncover what generated these charts." 
            cta="Go to feedback" 
            route={PathConstants.FEEDBACK} />
        </Grid>
      </Grid>
      <PageHeader
        title="Feedback trends"
        subtitle=""
      />
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {ratingDataX.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCardX {...card} />
          </Grid>
        ))}
      </Grid>
      </Box>
  );
}