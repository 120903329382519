import dayjs from "dayjs";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function convertDateToShort(dateYYYYmmdd: string | null) : string {
    if (dateYYYYmmdd) {
      if (dateYYYYmmdd.length === 10) {
        if (dayjs().format("YYYY") !== dateYYYYmmdd.substring(0, 4)) {
          return monthNamesShort[
              parseInt(
                  dateYYYYmmdd.split("-")[1]
              ) - 1
              ] 
              + " " + dateYYYYmmdd.split("-")[2]
              + ", '" + dateYYYYmmdd.substring(2, 4)
            } else {
              return monthNamesShort[
                parseInt(
                    dateYYYYmmdd.split("-")[1]
                ) - 1
                ] 
                + " " + dateYYYYmmdd.split("-")[2]
            }
        } else {
          return "";
        }
    } else {
        return "";
    }
}