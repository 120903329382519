import { Box, Typography } from "@mui/material";
import { useRouteError } from 'react-router-dom';

type RouteError = {
    statusText?: string,
    message?: string,
  }

export const ErrorPage = () => {     
    const error = useRouteError() as RouteError | null
    console.log(error)

    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            <Typography component="h2" variant="h4" sx={{ mb: 2 }}>Ooops, we have a problem. { error?.statusText }</Typography>
            <Typography sx={{ color: 'text.secondary', mb: '8px'  }}>{ error?.message }</Typography>
        </Box>
    );
};