import {FormControl, IconButton, Paper, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface StepEditProps {
    stepInfo: ActionItemStepInfo;
    onDeleteStep: () => void;
    onChange: (updatedStep: ActionItemStepInfo) => void;
}

export const StepEdit = ({
                             stepInfo,
                             onDeleteStep,
                             onChange,
                         }: StepEditProps) => {
    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value} = event.target;
        const updatedStep = {
            ...stepInfo,
            [name]: value,
        };
        onChange(updatedStep);
    }

    return (
        <Stack key={stepInfo.user_action_plan_step_id} direction={"row"} sx={{width: '100%'}}>
            <Paper sx={{padding: 2, flex: 1}}>
                <Stack spacing={2}>
                    <Stack spacing={4}>
                        <FormControl fullWidth>
                            <TextField
                                id="description"
                                label="Step Description"
                                defaultValue={stepInfo.user_action_plan_step_description}
                                name={"user_action_plan_step_description"}
                                onChange={handleOnChange}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                id="measurement"
                                label="Measurement (success criteria)"
                                defaultValue={stepInfo.user_action_plan_step_measurement}
                                name={"user_action_plan_step_measurement"}
                                onChange={handleOnChange}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <TextField
                            id="target_date"
                            label="Target Date"
                            type="date"
                            defaultValue={stepInfo.user_action_plan_step_target_date}
                            name={"user_action_plan_step_target_date"}
                            onChange={handleOnChange}
                        />
                        <TextField
                            id="completed_date"
                            label="Date Completed"
                            type="date"
                            defaultValue={stepInfo.user_action_plan_step_date_completed}
                            name={"user_action_plan_step_date_completed"}
                            onChange={handleOnChange}
                        />
                    </Stack>
                </Stack>
            </Paper>
            <IconButton aria-label="delete" size="small" color={"default"}
                        sx={{ml: 'auto', flex: 'none'}}>
                <DeleteIcon fontSize="small" color={"error"} onClick={onDeleteStep}/>
            </IconButton>
        </Stack>
    );
}
