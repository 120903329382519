import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

export const PathConstants = {
  LANDING: "/",
  ONBOARDING: "/onboarding",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  HOME: "/dashboard",
  ACTION_PLAN: "/action-plan",
  ACTION_PLAN_NEW: "/action-plan-new",
  TEAM_GOALS: "/team-goals",
  FEEDBACK: "/feedback",
  SETTINGS: "/settings",
  PROFILE: "/profile",
  ACCOUNT: "/account",
}

export const navMainListItems = [
  { text: 'Home', icon: <HomeRoundedIcon />, route: PathConstants.HOME},
  { text: 'Action plan', icon: <AssignmentIndOutlinedIcon />, route: PathConstants.ACTION_PLAN},
  { text: 'Team goals', icon: <ModeStandbyOutlinedIcon />, route: PathConstants.TEAM_GOALS},
  { text: 'Feedback', icon: <ChatOutlinedIcon />, route: PathConstants.FEEDBACK},
];
export const navSecondaryListItems = [
  { text: 'Settings', icon: <SettingsRoundedIcon />, route: PathConstants.SETTINGS},
  //{ text: 'About', icon: <InfoRoundedIcon />, route: 'about'}},
];
