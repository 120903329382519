import React, { useState, useEffect } from 'react';
import { Box, Grid2, Avatar, FormControlLabel, Switch } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbsUpDownOutlinedIcon from '@mui/icons-material/ThumbsUpDownOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

import { PageHeader } from "../_PageHeader";

import { convertDateToShort } from "../../utils/monthNames";
import { getFeedbacksProvided, getFeedbacksReceived, getFeedbacksSelf } from '../../services/FeedbackPublicAPI';
import { User, getCurrentUser } from "../../services/PublicAPI";

enum feedbackType{
  Received,
  Provided,
  Self
}

export const FeedbackPage = () =>{
  const [user, setUser] = React.useState<User>();
  const [feedbacks, setFeedback] = useState<FeedbackInfo[]>([]);
  const [tabValue, setTabValue] = React.useState<feedbackType>(feedbackType.Received);
  const [highQualityOnlyFeedback, setViewStatus] = useState<boolean>(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
        fetchFeedbacks(currentUser, feedbackType.Received, highQualityOnlyFeedback);
          } catch (err) {
        console.error("Failed to retrieve user. ", err);
      }
    };
    fetchUser();
  }, []);

  const fetchFeedbacks = async (user: User, type: feedbackType, highQualityOnlyFeedback: boolean) => {
    var results:FeedbackInfo[] = [];

    switch(type) {
      case feedbackType.Received: {
        results = await getFeedbacksReceived(user.user_id);
        break;
      }
      case feedbackType.Provided: {
        results = await getFeedbacksProvided(user.user_id);
        break;
      }
      case feedbackType.Self: {
        results = await getFeedbacksSelf(user.user_id);
        break;
      }
    }

    if (!highQualityOnlyFeedback){
      setFeedback(results);
    }
    else {
      var results_not_generic:FeedbackInfo[] = [];
      results.forEach( (element) => {
        if (element.feedback_notes)
          results_not_generic.push(element);
      });

      setFeedback(results_not_generic);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (user) {
      fetchFeedbacks(user, newValue, highQualityOnlyFeedback);
    }
  };
  const viewStatusChange = async () => {
    setViewStatus(!highQualityOnlyFeedback);
    if (user) {
      fetchFeedbacks(user, tabValue, !highQualityOnlyFeedback);
    }
  };


  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Grid2>
        <PageHeader title="Feedback"
        subtitle="Navigate the feedback you receive and provided."
      />
      </Grid2>
      <Grid2 container sx={{ my: 2 }} justifyContent="flex-center">
        <Grid2 size={8} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Received" />
            <Tab label="Provided" />
            <Tab label="Self" />
          </Tabs>
        </Grid2>
        <Grid2 size={4} container justifyContent="flex-end" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <FormControlLabel label="high quality only" control={<Switch defaultChecked onClick={viewStatusChange} />}/>
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ my: 1 }} justifyContent="flex-start">
        {feedbacks && feedbacks.length > 0 && 
          <TableContainer>
              <Table>
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" size='small'></TableCell>
                        <TableCell align="center" size='small'></TableCell>
                        <TableCell align="center" size='small'></TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="left">Feedback</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {feedbacks.map((feedback) => (
                      <TableRow key={feedback.feedback_id}>
                          <TableCell align="center"  size='small'>
                            <Avatar
                              sizes="small"
                              alt={tabValue === feedbackType.Received ? (feedback.feedback_reviewer_user_id) : (feedback.feedback_receiver_user_id)}
                              src="/static/images/avatar/7.jpg"
                              sx={{ width: 25, height: 25 }}
                              >
                                {tabValue === feedbackType.Received ? (feedback.feedback_reviewer_user_id) : (feedback.feedback_receiver_user_id)}
                            </Avatar>
                          </TableCell>
                          <TableCell align="center"  size='small'>
                            {feedback.feedback_rating === 5 && <StarBorderOutlinedIcon />}
                            {feedback.feedback_rating === 4 && <ThumbUpOutlinedIcon />}
                            {feedback.feedback_rating === 3 && <ThumbsUpDownOutlinedIcon />}
                            {feedback.feedback_rating === 2 && <ThumbDownOutlinedIcon />}
                            {feedback.feedback_rating === 1 && <WarningAmberOutlinedIcon />}
                          </TableCell>
                          <TableCell align="center"  size='small'>
                            {feedback.career_step_skill_id && <HandymanOutlinedIcon />}
                            {feedback.career_step_competency_id && <Diversity3OutlinedIcon />}
                            {feedback.kpi_id && <ModeStandbyOutlinedIcon />}
                            {feedback.user_action_plan_step_id && <AssignmentIndOutlinedIcon />}
                          </TableCell>
                          <TableCell align="center">{convertDateToShort(feedback.feedback_date)}</TableCell>
                          <TableCell align="left">{feedback.feedback_notes}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
          </TableContainer>
        }
      </Grid2>
    </Box>
  );
}