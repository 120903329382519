import * as React from 'react';
import { Box, Grid2 } from '@mui/material';

import { PageHeader } from "../_PageHeader";

export const SettingsPage = () =>{
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Grid2>
        <PageHeader title="Settings"
        subtitle="Setup your company key details: Mission, Vision, Values. 
        Advanced settings include roles, career paths and full skill/competency matrix."
      />
      </Grid2>
    </Box>
  );
}