import * as React from 'react';
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppTheme from '../shared-theme/AppTheme';

import FirebaseAuth from "../../services/authentication/firebase/Authentication";
import { PathConstants } from "../../utils/nav";

import AppNavbar from './AppNavBar';
import Header from './Header';
import SideMenu from './SideMenu';


export default function Layout(props: { disableCustomTheme?: boolean }) {
  const fa = new FirebaseAuth();
  const userInfo = fa.getUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo === null) {
      navigate(PathConstants.SIGN_IN);
    }
  }, [userInfo]);


  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      {userInfo &&
        <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Outlet />
          </Stack>
        </Box>
      </Box>
    }
    </AppTheme>
  );
}