import { Box, Typography } from "@mui/material";

interface HeaderProps {
    title: string;
    subtitle: string;
}

export const PageHeader = ({title, subtitle}: HeaderProps) => {
    return (
        <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            <Typography component="h2" variant="h4" sx={{ mb: 2 }}>{title}</Typography>
            {subtitle.length > 0 && <Typography sx={{ color: 'text.secondary', mb: '8px'  }}>{subtitle}</Typography>}
        </Box>
    );
};