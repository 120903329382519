import React from "react";

import { MissionStatement } from "./MissionStatement";
import { HeadLineStatement } from "./HeadLine";
import Waitlist from "./WaitList";

export const LandingPage = () => {
  return (
    <div className="pt-10">
      <HeadLineStatement />
      <Waitlist />
      <MissionStatement />
    </div>
  );
};
