import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import AppTheme from "./components/shared-theme/AppTheme";
import { CssBaseline } from "@mui/material";

import { PathConstants } from "./utils/nav";
import Layout from "./components/_spa/Layout";
import { ErrorPage } from "./components/_ErrorPage";

import { LandingPage } from "./components/landing-page/LandingPage";
import SignUp from "./components/sign-up/SignUp";
import { Onboarding } from "./components/onboarding/Onboarding";
import SignIn from "./components/sign-in/SignIn";

import MainGrid from "./components/dashboard/MainGrid";
import TeamGoalsPage from "./components/team-goals/TeamGoalsPage";
import { SettingsPage } from "./components/settings/SettingsPage";
import { FeedbackPage } from "./components/feedback/FeedbackPage";
import {ActionPlanPage} from "./components/action-plan/ActionPlanPage";


const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: PathConstants.HOME, element: <MainGrid /> },
      { path: PathConstants.ACTION_PLAN, element: <ActionPlanPage /> },
      { path: PathConstants.TEAM_GOALS, element: <TeamGoalsPage /> },
      { path: PathConstants.FEEDBACK, element: <FeedbackPage /> },
      { path: PathConstants.SETTINGS, element: <SettingsPage /> },
      { path: PathConstants.PROFILE, element: null },
      { path: PathConstants.ACCOUNT, element: null }
    ]
  },
  { path: PathConstants.LANDING, element: <LandingPage /> },
  { path: PathConstants.ONBOARDING, element: <Onboarding /> },
  { path: PathConstants.SIGN_UP, element: <SignUp /> },
  { path: PathConstants.SIGN_IN, element: <SignIn /> }
]);


const App: React.FC = (props: { disableCustomTheme?: boolean }) => {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </AppTheme>
  )
};

export default App;
