import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function BrancoIcon() {
  return (
    <SvgIcon>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="356.000000pt" height="390.000000pt" viewBox="0 0 356.000000 390.000000"
      preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,390.000000) scale(0.100000,-0.100000)">
          <path d="M363 3876 c-53 -17 -110 -52 -123 -76 -6 -10 -10 -34 -10 -52 0 -28
          12 -44 78 -109 87 -85 176 -207 204 -281 12 -31 21 -90 25 -157 l6 -107 -45
          -51 c-126 -142 -269 -389 -326 -563 -58 -179 -73 -262 -84 -455 -12 -225 0
          -402 36 -545 156 -611 700 -1078 1355 -1166 135 -18 232 -18 351 0 112 17 332
          79 481 135 59 22 131 43 160 47 l54 6 50 -68 c81 -109 180 -187 332 -260 73
          -35 165 -75 205 -88 90 -30 218 -49 276 -40 l43 6 -7 57 c-9 73 -103 334 -155
          431 -75 139 -158 229 -258 281 l-44 23 39 30 c190 152 360 467 402 745 l9 64
          -79 -7 c-170 -14 -308 -57 -459 -143 -122 -69 -178 -118 -252 -217 -73 -99
          -109 -169 -138 -270 -27 -92 -35 -99 -189 -178 -172 -88 -333 -133 -521 -144
          -236 -15 -500 52 -681 173 -90 60 -256 218 -313 298 -163 228 -196 623 -73
          873 47 94 117 175 253 294 100 87 198 143 333 188 142 48 281 53 1304 47 l887
          -4 7 31 c13 53 7 198 -10 265 -23 91 -96 225 -174 322 -124 153 -290 267 -478
          328 -173 55 -182 56 -804 56 -561 0 -577 -1 -670 -23 -52 -12 -119 -32 -148
          -43 l-53 -22 -97 95 c-101 100 -223 185 -329 232 -120 52 -279 70 -370 42z
          m2308 -679 c78 -53 97 -139 46 -216 -36 -54 -72 -66 -155 -53 -46 8 -117 8
          -229 2 -189 -11 -242 -4 -285 36 -79 76 -74 172 12 232 32 21 39 22 305 22
          267 0 273 0 306 -23z"/>
          <path d="M2151 2438 c-46 -4 -118 -17 -160 -27 -94 -23 -172 -37 -326 -56 -66
          -9 -123 -19 -128 -24 -4 -4 -12 -93 -16 -197 l-9 -189 -48 -105 c-45 -97 -49
          -111 -49 -183 0 -74 2 -80 28 -103 77 -66 335 7 502 142 80 64 215 258 258
          369 10 28 25 83 33 123 15 78 19 248 7 254 -5 2 -46 0 -92 -4z"/>
        </g>
      </svg>
    </SvgIcon>
  );
}

export function BrancoLogo() {
  return (
    <SvgIcon>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="701.000000pt" height="182.000000pt" viewBox="0 0 701.000000 182.000000"
      preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,182.000000) scale(0.100000,-0.100000)">
          <path d="M90 1190 c0 -661 -4 -626 75 -745 48 -72 152 -176 208 -209 206 -121
          455 -88 625 83 85 86 126 155 152 255 36 140 19 300 -45 425 -35 69 -131 161
          -214 205 -146 77 -353 67 -454 -22 -9 -8 -20 -12 -24 -9 -3 4 -7 142 -7 307
          l-1 300 -157 0 -158 0 0 -590z m624 -252 c55 -17 131 -91 145 -143 25 -90 9
          -170 -45 -233 -51 -60 -105 -86 -179 -86 -73 0 -124 24 -182 86 -48 53 -67
          103 -67 178 -1 90 53 161 148 197 41 15 129 16 180 1z"/>
          <path d="M4662 1403 c-7 -2 -17 -13 -22 -23 -8 -16 -2 -28 34 -69 56 -63 62
          -75 71 -131 6 -42 3 -49 -36 -100 -91 -121 -123 -217 -123 -380 -1 -94 4 -127
          22 -182 44 -127 135 -239 254 -311 92 -55 155 -72 278 -71 92 0 116 4 198 32
          51 18 98 32 103 32 6 0 31 -20 55 -45 52 -54 156 -104 232 -112 61 -7 62 -4
          33 79 -28 82 -49 119 -93 165 l-41 41 32 34 c41 42 99 160 107 217 l7 44 -51
          -6 c-115 -14 -230 -90 -286 -192 -44 -77 -84 -107 -176 -131 -205 -52 -431 75
          -471 264 -32 155 43 294 198 365 47 21 59 22 430 25 l383 3 6 24 c17 68 -49
          193 -135 257 -88 64 -124 72 -361 76 -160 2 -232 0 -282 -11 -37 -8 -65 -11
          -63 -7 8 14 -71 70 -134 96 -56 22 -136 31 -169 17z m864 -258 c15 -23 15 -27
          0 -50 -16 -24 -21 -25 -121 -25 -112 0 -135 9 -135 54 0 40 18 46 132 46 104
          0 108 -1 124 -25z"/>
          <path d="M1900 1247 c-72 -17 -130 -48 -171 -92 -18 -19 -37 -35 -40 -35 -4 0
          -11 25 -14 55 -4 30 -10 57 -14 59 -4 3 -65 8 -137 12 l-131 7 -7 -471 c-4
          -259 -4 -500 0 -536 l7 -66 148 0 149 0 0 286 c0 317 4 341 58 404 59 69 100
          85 223 84 l106 0 6 106 c4 58 12 122 18 143 6 22 9 40 7 42 -11 12 -161 13
          -208 2z"/>
          <path d="M2643 1249 c-266 -44 -463 -275 -463 -541 0 -150 61 -298 166 -398
          88 -85 173 -124 281 -128 97 -5 163 12 247 63 32 19 60 35 62 35 1 0 4 -24 6
          -52 2 -35 7 -53 16 -52 6 1 72 2 145 2 l134 2 -5 362 -4 363 -40 77 c-52 101
          -123 167 -231 218 -119 56 -200 68 -314 49z m177 -329 c66 -33 110 -111 118
          -206 4 -54 1 -68 -24 -113 -52 -96 -126 -138 -225 -129 -81 8 -174 92 -199
          179 -22 77 -9 162 33 207 76 82 204 108 297 62z"/>
          <path d="M3990 1250 c-71 -13 -97 -26 -156 -81 l-54 -52 0 49 c0 27 -4 54 -8
          60 -8 13 -231 29 -262 19 -20 -6 -20 -14 -20 -542 0 -484 2 -535 16 -529 9 3
          78 6 155 6 l139 0 0 323 0 323 37 44 c103 120 273 103 346 -35 20 -38 21 -59
          25 -352 l4 -313 156 0 155 0 -6 333 c-4 182 -12 355 -17 382 -26 128 -133 271
          -243 325 -34 16 -91 34 -127 40 -72 11 -76 11 -140 0z"/>
          <path d="M6204 1236 c-87 -28 -164 -78 -236 -153 -72 -76 -104 -133 -128 -228
          -32 -124 -21 -267 28 -377 46 -106 193 -238 309 -280 71 -26 225 -35 304 -19
          83 17 173 64 236 123 63 60 167 210 183 266 18 62 24 202 11 265 -40 187 -211
          359 -411 412 -84 22 -211 19 -296 -9z m251 -301 c111 -45 173 -188 131 -300
          -40 -104 -168 -172 -273 -145 -56 14 -113 42 -134 66 -34 38 -62 110 -66 169
          -5 55 -3 64 28 110 74 108 197 148 314 100z"/>
          <path d="M5223 877 l-123 -20 0 -58 c0 -45 -7 -72 -30 -116 -34 -67 -32 -112
          4 -114 102 -5 198 58 253 165 25 50 42 150 27 158 -5 3 -64 -4 -131 -15z"/>
        </g>
      </svg>
    </SvgIcon>
  );
}

export function TribeUXIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 100 }}>
      <svg
        width={86}
        height={19}
        viewBox="0 0 86 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#B4C0D3"
          d="m.787 12.567 6.055-2.675 3.485 2.006.704 6.583-4.295-.035.634-4.577-.74-.422-3.625 2.817-2.218-3.697Z"
        />
        <path
          fill="#00D3AB"
          d="m10.714 11.616 5.352 3.908 2.112-3.767-4.295-1.725v-.845l4.295-1.76-2.112-3.732-5.352 3.908v4.013Z"
        />
        <path
          fill="#4876EF"
          d="m10.327 7.286.704-6.583-4.295.07.634 4.577-.74.422-3.66-2.816L.786 6.617l6.055 2.676 3.485-2.007Z"
        />
      </svg>
    </SvgIcon>
  );
}

export function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 100 }}>
      <svg
        width={86}
        height={19}
        viewBox="0 0 86 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#B4C0D3"
          d="m.787 12.567 6.055-2.675 3.485 2.006.704 6.583-4.295-.035.634-4.577-.74-.422-3.625 2.817-2.218-3.697Z"
        />
        <path
          fill="#00D3AB"
          d="m10.714 11.616 5.352 3.908 2.112-3.767-4.295-1.725v-.845l4.295-1.76-2.112-3.732-5.352 3.908v4.013Z"
        />
        <path
          fill="#4876EF"
          d="m10.327 7.286.704-6.583-4.295.07.634 4.577-.74.422-3.66-2.816L.786 6.617l6.055 2.676 3.485-2.007Z"
        />
        <path
          fill="#4876EE"
          d="M32.507 8.804v6.167h2.312v-7.86h-3.366v1.693h1.054ZM32.435 6.006c.212.22.535.33.968.33.434 0 .751-.11.953-.33.213-.23.318-.516.318-.86 0-.354-.105-.641-.318-.86-.202-.23-.52-.345-.953-.345-.433 0-.756.115-.968.344-.202.22-.303.507-.303.86 0 .345.101.632.303.861ZM24.46 14.799c.655.296 1.46.444 2.413.444.896 0 1.667-.139 2.312-.416.645-.277 1.141-.664 1.488-1.162.357-.506.535-1.094.535-1.764 0-.65-.169-1.2-.506-1.649-.328-.459-.785-.818-1.373-1.076-.587-.267-1.266-.435-2.037-.502l-.809-.071c-.481-.039-.828-.168-1.04-.388a1.08 1.08 0 0 1-.318-.774c0-.23.058-.44.173-.631.116-.201.29-.359.52-.474.241-.114.535-.172.882-.172.366 0 .67.067.91.201.053.029.104.059.15.09l.012.009.052.037c.146.111.263.243.35.395.125.21.188.444.188.703h2.311c0-.689-.159-1.286-.476-1.793-.318-.516-.776-.913-1.373-1.19-.588-.287-1.296-.43-2.124-.43-.79 0-1.474.133-2.052.4a3.131 3.131 0 0 0-1.358 1.12c-.318.487-.477 1.066-.477 1.735 0 .927.314 1.673.94 2.237.626.564 1.464.89 2.514.976l.794.071c.645.058 1.113.187 1.401.388a.899.899 0 0 1 .434.788 1.181 1.181 0 0 1-.231.717c-.154.201-.38.36-.68.474-.298.115-.669.172-1.112.172-.49 0-.89-.067-1.199-.2-.308-.144-.539-.33-.694-.56a1.375 1.375 0 0 1-.216-.746h-2.297c0 .679.168 1.281.505 1.807.337.517.834.928 1.489 1.234ZM39.977 15.07c-.8 0-1.445-.095-1.936-.286a2.03 2.03 0 0 1-1.084-.99c-.221-.469-.332-1.1-.332-1.893V8.789h-1.2V7.11h1.2V4.988h2.153V7.11h2.312V8.79h-2.312v3.198c0 .373.096.66.289.86.202.192.486.287.852.287h1.17v1.937h-1.112Z"
        />
        <path
          fill="#4876EE"
          fillRule="evenodd"
          d="M43.873 14.899c.52.23 1.117.344 1.791.344.665 0 1.252-.115 1.763-.344.51-.23.934-.55 1.271-.96.337-.412.564-.88.679-1.407h-2.124c-.096.24-.279.44-.549.603-.27.162-.616.244-1.04.244-.262 0-.497-.031-.704-.093a1.572 1.572 0 0 1-.423-.194 1.662 1.662 0 0 1-.636-.803 3.159 3.159 0 0 1-.163-.645h5.784v-.775a4.28 4.28 0 0 0-.463-1.98 3.686 3.686 0 0 0-1.343-1.477c-.578-.382-1.291-.574-2.139-.574-.645 0-1.223.115-1.733.345-.501.22-.92.52-1.257.903a4.178 4.178 0 0 0-.78 1.305c-.174.478-.26.98-.26 1.506v.287c0 .507.086 1.004.26 1.492.183.478.443.913.78 1.305.347.382.775.688 1.286.918Zm-.094-4.674.02-.09a2.507 2.507 0 0 1 .117-.356c.145-.354.356-.622.636-.804.104-.067.217-.123.339-.165.204-.071.433-.107.686-.107.395 0 .723.09.983.272.27.173.472.426.607.76a2.487 2.487 0 0 1 .16.603h-3.57c.006-.038.013-.076.022-.113Z"
          clipRule="evenodd"
        />
        <path
          fill="#4876EE"
          d="M50.476 14.97V7.112h1.835v1.98a4.54 4.54 0 0 1 .173-.603c.202-.536.506-.937.91-1.205.405-.277.9-.416 1.488-.416h.101c.598 0 1.094.139 1.489.416.404.268.707.67.91 1.205l.016.04.013.037.028-.077c.212-.536.52-.937.925-1.205.405-.277.901-.416 1.489-.416h.1c.598 0 1.098.139 1.503.416.414.268.727.67.94 1.205.211.535.317 1.205.317 2.008v4.475h-2.312v-4.604c0-.43-.115-.78-.346-1.047-.222-.268-.54-.402-.954-.402-.414 0-.742.139-.982.416-.241.268-.362.626-.362 1.076v4.56h-2.326v-4.603c0-.43-.115-.78-.346-1.047-.222-.268-.535-.402-.94-.402-.423 0-.756.139-.996.416-.241.268-.362.626-.362 1.076v4.56h-2.311Z"
        />
        <path
          fill="#4876EE"
          fillRule="evenodd"
          d="M68.888 13.456v1.515h1.834v-4.82c0-.726-.144-1.319-.433-1.778-.289-.468-.712-.817-1.271-1.047-.549-.23-1.228-.344-2.037-.344a27.76 27.76 0 0 0-.896.014c-.318.01-.626.024-.924.043l-.229.016a36.79 36.79 0 0 0-.552.042v1.936a81.998 81.998 0 0 1 1.733-.09 37.806 37.806 0 0 1 1.171-.025c.424 0 .732.1.925.301.193.201.289.502.289.904v.029h-1.43c-.704 0-1.325.09-1.864.272-.54.172-.959.445-1.257.818-.299.363-.448.832-.448 1.405 0 .526.12.98.361 1.363.24.373.573.66.997.86.433.201.934.302 1.502.302.55 0 1.012-.1 1.388-.302.385-.2.683-.487.895-.86a2.443 2.443 0 0 0 .228-.498l.018-.056Zm-.39-1.397v-.63h-1.445c-.405 0-.718.1-.939.3-.212.192-.318.455-.318.79 0 .157.026.3.08.429a.99.99 0 0 0 .238.345c.221.191.534.287.939.287a2.125 2.125 0 0 0 .394-.038c.106-.021.206-.052.3-.092.212-.095.385-.253.52-.473.135-.22.212-.526.23-.918Z"
          clipRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
}

export function FacebookIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.68 15.92C2.88 15.24 0 11.96 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 11.96 13.12 15.24 9.32 15.92L8.88 15.56H7.12L6.68 15.92Z"
          fill="url(#paint0_linear_795_116)"
        />
        <path
          d="M11.12 10.2391L11.48 7.99914H9.36V6.43914C9.36 5.79914 9.6 5.31914 10.56 5.31914H11.6V3.27914C11.04 3.19914 10.4 3.11914 9.84 3.11914C8 3.11914 6.72 4.23914 6.72 6.23914V7.99914H4.72V10.2391H6.72V15.8791C7.16 15.9591 7.6 15.9991 8.04 15.9991C8.48 15.9991 8.92 15.9591 9.36 15.8791V10.2391H11.12Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_795_116"
            x1="8"
            y1="0"
            x2="8"
            y2="15.9991"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1AAFFF" />
            <stop offset="1" stopColor="#0163E0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export function GoogleIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.68 8.18182C15.68 7.61455 15.6291 7.06909 15.5345 6.54545H8V9.64364H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0655H13.2945C14.8073 12.6691 15.68 10.6182 15.68 8.18182Z"
          fill="#4285F4"
        />
        <path
          d="M8 16C10.16 16 11.9709 15.2873 13.2945 14.0655L10.6982 12.0509C9.98545 12.5309 9.07636 12.8218 8 12.8218C5.92 12.8218 4.15273 11.4182 3.52 9.52727H0.858182V11.5927C2.17455 14.2036 4.87273 16 8 16Z"
          fill="#34A853"
        />
        <path
          d="M3.52 9.52C3.36 9.04 3.26545 8.53091 3.26545 8C3.26545 7.46909 3.36 6.96 3.52 6.48V4.41455H0.858182C0.312727 5.49091 0 6.70545 0 8C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52Z"
          fill="#FBBC05"
        />
        <path
          d="M8 3.18545C9.17818 3.18545 10.2255 3.59273 11.0618 4.37818L13.3527 2.08727C11.9636 0.792727 10.16 0 8 0C4.87273 0 2.17455 1.79636 0.858182 4.41455L3.52 6.48C4.15273 4.58909 5.92 3.18545 8 3.18545Z"
          fill="#EA4335"
        />
      </svg>
    </SvgIcon>
  );
}
