import React, {useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {StepCard} from "./StepCard";
import {DeleteOutlined} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {StepSuggestionCard} from "./StepSuggestionCard";

interface ActionPlanItemCardProps {
    plan: ActionItemInfo;
    stepSuggestions: Array<ActionItemStepSuggestionInfo>;
    onDelete: (id: number) => void;
    onEdit: () => void;
    onActionItemCompleted: (isCompleted: boolean) => void;
    onStepCompleted: (id: number, isCompleted: boolean) => void;
    onStepSuggestionAccepted: (id: number, isAccepted: boolean) => void;
}

export const ActionPlanItemCard = ({
                                       plan,
                                       stepSuggestions,
                                       onDelete,
                                       onEdit,
                                       onActionItemCompleted,
                                       onStepCompleted,
                                       onStepSuggestionAccepted,
                                   }: ActionPlanItemCardProps) => {
    const [actionPlan] = useState<ActionItemInfo>(plan);

    function handleEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onEdit();
    }

    function handleActionItemCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onActionItemCompleted(isChecked);
    }

    function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onDelete(plan.user_action_plan_id);
    }

    // List of action items and action item suggestions
    const actionPlanContent = (
        <Table sx={{tableLayout: "auto"}}>
            <TableBody>
                <TableRow sx={{
                    // Removes the bottom border of the last table row
                    "&:last-child td, &:last-child th": {
                        borderBottom: "none",
                    },
                }}>
                    <TableCell size="small" width="5%">
                        <Checkbox checked={!!actionPlan.user_action_plan_date_completed}
                                  onChange={handleActionItemCompleted}/>
                    </TableCell>
                    <TableCell align="left">
                        {actionPlan.user_action_plan_goal}
                    </TableCell>
                    <TableCell align="right" sx={{
                        padding: 0, // Remove all internal padding
                        margin: 0,  // Ensure no margin is applied
                        whiteSpace: "nowrap", // Prevent content from breaking to a new line
                        width: "1%",
                    }}>
                        <Stack direction={"row"} justifyContent="flex-end" spacing={1}>
                            <Button color="info"
                                    sx={{
                                        padding: 0,
                                        margin: 0,
                                        minWidth: 0, // Remove the default minimum width
                                        lineHeight: 1, // Ensure no extra line height
                                    }}
                                    onClick={handleDelete}>
                                <DeleteOutlined fontSize="small"/>
                            </Button>
                            <Button color="info" sx={{
                                padding: 0,
                                margin: 0,
                                minWidth: 0,
                                lineHeight: 1
                            }} onClick={handleEdit}>
                                <EditOutlinedIcon fontSize="small"/>
                            </Button>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const renderStepCards = () =>
        (plan.user_action_plan_steps || []).map((itemStep) => (
            <StepCard
                stepInfo={itemStep}
                key={itemStep.user_action_plan_step_id}
                onStepCompleted={onStepCompleted}
            />
        ));

    const renderStepSuggestionCards = () =>
        (stepSuggestions || []).map((itemSuggestedStep) => (
            <StepSuggestionCard
                stepSuggestionInfo={itemSuggestedStep}
                onAcceptSuggestionStep={onStepSuggestionAccepted}
                key={itemSuggestedStep.user_action_plan_step_suggestion_id}
            />
        ));

    // List of steps and step suggestions
    const stepsContent = (
        <Stack spacing={2}>
            <Table>
                <TableBody>
                    {renderStepCards()}
                    {renderStepSuggestionCards()}
                </TableBody>
            </Table>

        </Stack>
    );

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                id="panel-header"
            >
                {actionPlanContent}
            </AccordionSummary>
            <AccordionDetails>
                {stepsContent}
            </AccordionDetails>
        </Accordion>
    );
};