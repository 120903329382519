import React from "react";
import {Chip, IconButton, TableCell, TableRow} from "@mui/material";
import {convertDateToShort} from "../../utils/monthNames";
import Stack from "@mui/material/Stack";
import {ThumbDownAltOutlined, ThumbUpAltOutlined} from "@mui/icons-material";

interface StepSuggestionProps {
    stepSuggestionInfo: ActionItemStepSuggestionInfo;
    onAcceptSuggestionStep: (suggestionId: number, isAccepted: boolean) => void;
}

export const StepSuggestionCard = ({stepSuggestionInfo, onAcceptSuggestionStep}: StepSuggestionProps) => {
    function handleAcceptSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onAcceptSuggestionStep(stepSuggestionInfo.user_action_plan_step_suggestion_id, true);
    }

    function handleRejectSuggestion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        onAcceptSuggestionStep(stepSuggestionInfo.user_action_plan_step_suggestion_id, false);
    }

    return (
        <TableRow>
            <TableCell size="small" width="5%"/>
            <TableCell align="left" sx={{
                color: "info.main", // Use secondary color for text
            }}>
                {stepSuggestionInfo.user_action_plan_step_suggestion_description}
                <Chip color="primary" variant="outlined"
                      label={`by ${convertDateToShort(stepSuggestionInfo.user_action_plan_step_suggestion_target_date)}`}
                      size="small"
                      sx={{marginLeft: 1}}/>
            </TableCell>
            <TableCell align={"right"}>
                <Stack direction={"row"} justifyContent="flex-end">
                    <IconButton size="small" color={"primary"}
                                onClick={handleAcceptSuggestion}>
                        <ThumbUpAltOutlined fontSize="small"/>
                    </IconButton>
                    <IconButton size="small" color={"primary"}
                                onClick={handleRejectSuggestion}>
                        <ThumbDownAltOutlined fontSize="small"/>
                    </IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
};
