const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;


export const getFeedbackDashboard = async (UserId: number) => {
  try {
    var params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/feedback_summary?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getFeedbacksReceived = async (UserId: number) => {
  try {
    var params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/feedbacks/received?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getFeedbacksProvided = async (UserId: number) => {
  try {
    var params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/feedbacks/provided?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getFeedbacksSelf = async (UserId: number) => {
  try {
    var params = {user_id: UserId.toString()};

    const response = await fetch(
      `${PUBLIC_API_URL}/feedbacks/self?` +
      new URLSearchParams(params).toString(),
    {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
