import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TribeUXIcon } from '../_common/CustomIcons';
import MenuContent from './MenuContent';
import AlertCard from '../_AlertCard';
import { PathConstants } from '../../utils/nav';


const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
        {/*
        <SelectContent />
        */}
      <TribeUXIcon />
      </Box>
      <Divider />
      <MenuContent />
      <AlertCard 
        title="Plan about to expire" 
        offer="Enjoy 10% off when renewing your plan today." 
        cta="Get the discount" 
        route={PathConstants.ACCOUNT} />
    </Drawer>
  );
}