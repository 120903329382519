import { get } from "./helpers";

const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

export type User = {
  user_id: number;
  company_id: number;
  user_name?: string;
  org_id?: number;
  org_id_date_since?: Date;
  role_id?: number;
  role_id_date_since?: Date;
  career_step_id?: number;
  career_step_id_date_since?: Date;
  level_id?: number;
  level_id_date_since?: Date;
  user_email?: string;
};

export const getCurrentUser = async (): Promise<User> => {
  return await get<User>("/users/me");
};

export const getUser = async (UserId: number) => {
  try {
    const response = await fetch(
      `${PUBLIC_API_URL}/users/user_id?` +
        new URLSearchParams({
          user_id: UserId.toString(),
        }).toString(),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export type CompanySeeded = {
  company_name: string;
  company_domain?: string;
  company_id: number;
};
export const seedCompany = async (Email: string) => {
  try {
    const response = await fetch(`${PUBLIC_API_URL}/companies/seed`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_work_email: Email,
        user_name: Email, // Assuming user name is also the email for simplicity
      }),
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return response.json();
  } catch (err) {
    console.error(err);
  }
};
