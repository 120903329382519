import {
    Accordion,
    AccordionActions,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    Grid2,
    Paper,
    TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useState} from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import {StepEdit} from "./StepEdit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

interface ActionItemCardEditProps {
    plan: ActionItemInfo;
    onCancel: () => void;
    onSave: (item: ActionItemInfo) => void;
    onDeleteStep: () => void;
}

export const ActionPlanItemCardEdit = ({
                                           plan,
                                           onCancel,
                                           onSave,
                                           onDeleteStep,
                                       }: ActionItemCardEditProps) => {
    const [actionItem, setActionItem] = useState<ActionItemInfo>(plan);

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        event.stopPropagation();
        const {name, value} = event.target;
        setActionItem((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically update the field
        }));
    }

    function handleOnStepChange(updatedStep: ActionItemStepInfo) {
        const updatedSteps = (actionItem.user_action_plan_steps || []).map((step) => {
            if (step.user_action_plan_step_id === updatedStep.user_action_plan_step_id) {
                return {...updatedStep};
            }
            return step;
        });
        setActionItem((prevData) => ({
            ...prevData,
            user_action_plan_steps: [...updatedSteps],
        }));
    }

    async function onOK() {
        // TODO: this needs to change:
        // 1. We need to be able to spread the object instead of passing individual fields
        // 2. We need ot be able to update the entire action item (including steps) in one API call
        try {
            onSave(actionItem);
            // TODO: handle success
        } catch (error) {
            // TODO: handle failure
            console.error(error);
        }
    }

    const actionItemEditTitle = (
        <div className="text-left" style={{width: '100%'}}>
            {/* Edit action item goal */}
            <Box sx={{p: 1}}>
                <FormControl fullWidth>
                    <TextField
                        name="user_action_plan_goal"
                        label="Description"
                        defaultValue={actionItem.user_action_plan_goal}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={handleOnChange}
                    />
                </FormControl>
            </Box>
        </div>
    )

    const toolBarContent = (
        <>
            <Button variant="outlined" startIcon={<AddIcon/>} color={"secondary"}>
                Add Task
            </Button>
            <Button variant="outlined" startIcon={<CancelIcon/>} color={"error"} onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="outlined" startIcon={<CheckIcon/>} onClick={onOK}>
                OK
            </Button>
        </>
    )

    const actionItemEditFields = (
        <Stack direction={"row"} sx={{width: '100%', flex: 1}}>
            <Paper sx={{padding: 2, mb: 2, width: '100%'}}>
                {/*Edit action item priority, date completed, and target date*/}

                <FormControl fullWidth sx={{gap: 2}}>
                    <TextField
                        name="user_action_plan_business_value"
                        label="Business Value"
                        defaultValue={actionItem.user_action_plan_business_value}
                        onChange={handleOnChange}
                        multiline
                    />
                </FormControl>
                <Grid2
                    sx={{
                        textAlign: "left",
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                    }}
                >
                    <TextField
                        name="user_action_plan_priority"
                        label="Priority"
                        type="number"
                        defaultValue={actionItem.user_action_plan_priority}
                        onChange={handleOnChange}
                    />
                    <TextField
                        name="user_action_plan_date_completed"
                        label="Date Completed"
                        type="date"
                        defaultValue={actionItem.user_action_plan_date_completed}
                        onChange={handleOnChange}
                        slotProps={{inputLabel: {shrink: true}}}
                    />
                </Grid2>
            </Paper>
        </Stack>
    )
    return <Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-between"
        >
            {actionItemEditTitle}
        </AccordionSummary>
        <AccordionDetails>
            {actionItemEditFields}
            <Stack spacing={2}>
                {
                    (actionItem.user_action_plan_steps || []).map((step) => {
                        return <StepEdit stepInfo={step}
                                         key={step.user_action_plan_step_id}
                                         onChange={handleOnStepChange}
                                         onDeleteStep={onDeleteStep}/>
                    })
                }
            </Stack>
        </AccordionDetails>
        <AccordionActions>
            {toolBarContent}
        </AccordionActions>
    </Accordion>
}
