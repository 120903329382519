import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Grid2,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useMemo, useState } from "react";
import { ReactComponent as TribeuxLogo } from "../../images/TribeuxLogo.svg";
import { LoginPage } from "./LoginPage";
//import { RoleComponent } from "./RoleComponent";
import { RoleComponentEng } from "./RoleComponentEng";
import { TeamComponent } from "./TeamComponent";
import { YouComponent } from "./YouComponent";
import { useNavigate } from "react-router-dom";
import { PathConstants } from "../../utils/nav";

export const Onboarding = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const navigate = useNavigate();

  const ONBOARDING_STEPS = useMemo(() => {
    return [
      { name: "Role", component: <RoleComponentEng /> },
      { name: "Team", component: <TeamComponent /> },
      { name: "You", component: <YouComponent /> },
    ];
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleNext = () => {
    if (activeStep === 2) {
      // Save All Parts of Onboarding
      navigate(PathConstants.HOME);
    } else 
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setLoggedIn(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return loggedIn ? (
    <Container sx={{ textAlign: "center" }} maxWidth="xs">
      <TribeuxLogo />
      <h1 className="title">{ONBOARDING_STEPS[activeStep].name}</h1>
      <Stepper sx={{ mb: 3 }} activeStep={activeStep}>
        {ONBOARDING_STEPS.map((step) => {
          return (
            <Step key={step.name}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {ONBOARDING_STEPS[activeStep].component}
      <Grid2 container sx={{ mt: 3 }} justifyContent="flex-end">
        <ButtonGroup variant="contained">
          <Button color="secondary" onClick={handleBack}>
            BACK
          </Button>
          <Button color="primary" onClick={handleNext}>
            {activeStep === 2 ? "COMPLETE ONBOARDING" : "NEXT"}
          </Button>
        </ButtonGroup>
      </Grid2>
    </Container>
  ) : (
    <LoginPage setLogin={handleLogin} />
  );
};
