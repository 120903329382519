import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import { useNavigate } from "react-router-dom";
import { navMainListItems, navSecondaryListItems } from "../../utils/nav.js";

export default function MenuContent() {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (
    index: number,
    route: string,
  ) => {
    setSelectedIndex(index);
    navigate(route);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {navMainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={() => handleListItemClick(index, item.route)}>
            <ListItemButton selected={index === selectedIndex}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {navSecondaryListItems.map((item, index) => (
          <ListItem key={index+100} disablePadding sx={{ display: 'block' }} onClick={() => handleListItemClick(index+100, item.route)}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}