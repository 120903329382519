import React, { useState } from "react";
import {
  Button,
  Grid2,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  FormHelperText
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export const TeamComponent = () => {
  const [objectives, setObjectives] = useState<string[]>([""]);

  const addObjective = () => {
    setObjectives([...objectives, ""]);
  };

  const removeObjective = (index: number) => {
    setObjectives(objectives.filter((_, i) => i !== index));
  };

  const updateObjective = (index: number, newObjective: string) => {
    const updatedObjectives = objectives.map((objective, i) => {
      if (i === index) {
        return newObjective;
      } else {
        return objective;
      }
    });
    setObjectives(updatedObjectives);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 3, textAlign: "left" }}
      >
        What is your team working on?
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 3, textAlign: "left" }}>
        "Goals and Objectives", "OKR", "Projects and Milestones"... <br />
        However you call them, they go here :)
      </Typography>
      <Typography sx={{ mb: 3, textAlign: "left" }}>
        <TextField label="Team goal *" fullWidth placeholder="Team goal *" />
        <FormHelperText sx={{ textAlign: "center" }}>
          Just one: the top priority goal for your team, you can add more later.
        </FormHelperText>
      </Typography>
      {objectives.map((objective, index) => {
        return (
          <TextField
            name={index.toString()}
            key={index}
            required={index === 0}
            fullWidth
            multiline
            label="Team objective(s)"
            slotProps={
              index !== 0
                ? {
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => removeObjective(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }
                : {}
            }
            value={objective}
            onChange={(e) => updateObjective(index, e.target.value)}
            sx={{ mb: 1 }}
          />
        );
      })}
      <Grid2 container sx={{ mt: 1 }} justifyContent="flex-end">
        <Button
          onClick={() => addObjective()}
          startIcon={<AddIcon />}
          sx={{ textAlign: "right" }}
        >
          Team objective
        </Button>
      </Grid2>
    </>
  );
};
