import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface HighilightedCardProps {
  title?: string;
  details?: string;
  cta?: string;
  route?: string;
}

export default function HighlightedCard({title, details, cta, route}: HighilightedCardProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <InsightsRoundedIcon />
        {title &&
          <Typography
            component="h2"
            variant="subtitle2"
            gutterBottom
            sx={{ fontWeight: '600' }}
          >
            {title}
          </Typography>
        }
        {details && 
          <Typography sx={{ color: 'text.secondary', mb: '8px' }}>
            {details}
          </Typography>
        }
        {cta && 
          <Button
            variant="contained"
            size="small"
            color="primary"
            endIcon={<ChevronRightRoundedIcon />}
            fullWidth={isSmallScreen}
            href={route}
          >
            {cta}
          </Button>
        }
      </CardContent>
    </Card>
  );
}