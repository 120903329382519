import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "./config";

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default class FirebaseAuth {
  getIdToken = async () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  };

  getUserInfo = () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      return currentUser as UserInfo;
    }
    return null;
  };
}
