import React, {useEffect, useState} from "react";
import {Box, Grid2, List,} from "@mui/material";

import {getActionPlan, updateActionPlanAggregate} from "../../services/ActionPlanPublicAPI";
import {getActionPlanSuggestions} from "../../services/ActionPlanSuggestionsPublicAPI";
import {sortActionPlans, sortActionPlanSteps,} from "../../utils/ActionPlanSorting";
import {ActionPlanItemCard} from "./ActionPlanItemCard";
import {ActionPlanSuggestionCard} from "./ActionPlanSuggestionCard";
import {ActionPlanItemCardEdit} from "./ActionPlanItemCardEdit";
import {PageHeader} from "../_PageHeader";
import {getCurrentUser, User} from "../../services/PublicAPI";


type ActionPlanMapping = {
    actionPlan: ActionItemInfo;
    stepSuggestions: ActionItemStepSuggestionInfo[];
}

interface ListItemContainerProps {
    itemInfo: ActionItemInfo;
    stepSuggestions: Array<ActionItemStepSuggestionInfo>;
    onSave: (updatedItem: ActionItemInfo) => void;
}

const ListItemContainer = ({itemInfo, stepSuggestions, onSave}: ListItemContainerProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => setIsEditing(true);
    const handleCancelEdit = () => setIsEditing(false);

    function handleSave(updatedItem: ActionItemInfo) {
        setIsEditing(false);
        onSave(updatedItem);
    }

    function handleDeleteActionItem() {
        // TODO: delete action item
    }

    function handleDeleteStep() {
        // TODO: delete step
    }

    function handleActionItemCompleted(isCompleted: boolean) {
        // TODO: complete action item
    }

    function handleStepCompleted(stepId: number, isCompleted: boolean) {
        // TODO: complete action item
    }

    function handleAcceptSuggestionStep(stepSuggestionId: number, isAccepted: boolean) {
        // TODO: handle suggestion acceptance
        // TODO: this is a duplication of the same function we have in ListItemSuggestionContainer
    }

    return isEditing ? (
        <ActionPlanItemCardEdit plan={itemInfo} onCancel={handleCancelEdit}
                                onSave={handleSave} onDeleteStep={handleDeleteStep}/>
    ) : (
        <ActionPlanItemCard
            plan={itemInfo}
            onDelete={handleDeleteActionItem}
            onEdit={handleEdit}
            onActionItemCompleted={handleActionItemCompleted}
            onStepCompleted={handleStepCompleted}
            onStepSuggestionAccepted={handleAcceptSuggestionStep}
            stepSuggestions={stepSuggestions}
        />
    );
};

interface ListItemSuggestionContainerProps {
    suggestionItem: ActionItemSuggestionInfo;
}

const ListItemSuggestionContainer = ({suggestionItem}: ListItemSuggestionContainerProps) => {
    function handleAcceptSuggestion(isAccepted: boolean) {
        // TODO: handle suggestion acceptance
    }

    function handleAcceptSuggestionStep(stepSuggestionId: number, isAccepted: boolean) {
        // TODO: handle suggestion acceptance
    }

    return (
        <ActionPlanSuggestionCard
            suggestionInfo={suggestionItem}
            key={`goal-${suggestionItem.user_action_plan_suggestion_id}`}
            onAcceptSuggestion={handleAcceptSuggestion}
            onAcceptSuggestionStep={handleAcceptSuggestionStep}
        />
    );
}

export const ActionPlanPage = () => {
        const [user, setUser] = React.useState<User>();
        const [actionPlanMapping, setActionPlanMapping] = useState<ActionPlanMapping[]>([]);
        const [actionPlanSuggestions, setActionPlanSuggestions] = useState<ActionItemSuggestionInfo[]>([]);

    useEffect(() => {
        getCurrentUser()
            .then((currentUser) => setUser(currentUser))
            .catch((err) => console.error("Failed to retrieve user. ", err));
    }, []);

    useEffect(() => {
        if (!user) return; // Only run if user is set
        fetchDataAndCreateMapping().catch((err) =>
                console.error("Failed to retrieve action plan. ", err)
            // TODO: show error UI here
        );
    }, [user]); // Run when we have the `user` object available (or when it changes)

        const fetchActionPlan = async (id: number) => {
            return await getActionPlan(id).then((value) => {
                const actionPlans = value as ActionItemInfo[]
                actionPlans.forEach((plan) => {
                    if (plan.user_action_plan_steps) {
                        plan.user_action_plan_steps.sort(sortActionPlanSteps);
                    }
                });
                return actionPlans.sort(sortActionPlans)
            });
        };

        const fetchActionPlanSuggestions = async (id: number) => {
            return await getActionPlanSuggestions(id).then((value) => {
                return value as ActionItemSuggestionInfo[]
            });
        };

        const fetchDataAndCreateMapping = async () => {
            if (!user) {
                console.error("Current user is not set");
                return;
            }
            const actionPlans = await fetchActionPlan(user.user_id);
            const suggestions = await fetchActionPlanSuggestions(user.user_id);

            const actionPlanMapping: ActionPlanMapping[] = [];

            // Populate existing actionPlan with suggested new steps
            for (let i = 0; i < actionPlans.length; i++) {
                // Find suggestions associated with this action plan
                const actionPlanStepSuggestions = suggestions.find(
                    (suggestion) => suggestion.user_action_plan_id == actionPlans[i].user_action_plan_id)?.user_action_plan_step_suggestions || [];

                // Filter only new suggestions
                const newStepSuggestions = actionPlanStepSuggestions.filter((suggestion) => suggestion.user_action_plan_step_id == null)

                actionPlanMapping.push({
                    actionPlan: {
                        ...actionPlans[i], // Shallow copy of the outer object
                        user_action_plan_steps: [...(actionPlans[i].user_action_plan_steps || [])], // Copy of the nested array
                    },
                    stepSuggestions: newStepSuggestions,
                });
            }
            setActionPlanMapping(actionPlanMapping);

            // Populate suggested new actionPlan with suggested new steps
            const actionPlanSuggestions = suggestions.filter((suggestion) => suggestion.user_action_plan_id == null);
            setActionPlanSuggestions(actionPlanSuggestions);
        }

        function handleSave(updatedItem: ActionItemInfo) {
            updateActionPlanAggregate(updatedItem).then(() => {
                // TODO: handle success
                // Update mapping
                const updatedMapping = actionPlanMapping.map((value) => {
                    if (value.actionPlan.user_action_plan_id === updatedItem.user_action_plan_id) {
                        return {
                            actionPlan: {
                                ...updatedItem,
                                user_action_plan_steps: [...(updatedItem.user_action_plan_steps || [])]
                            },
                            stepSuggestions: [...value.stepSuggestions],
                        };
                    }
                    return {...value};
                });
                setActionPlanMapping(updatedMapping);
            }).catch(e => {
                // TODO: handle failure
            });
        }

        const renderActionItems = () => (
            actionPlanMapping.map((value) => {
                return (
                    <ListItemContainer
                        itemInfo={value.actionPlan}
                        stepSuggestions={value.stepSuggestions}
                        // TODO: replace Date.now with a unique id in the mapping array
                        key={`goal-${value.actionPlan.user_action_plan_id}-${Date.now()}`}
                        onSave={handleSave}
                    />
                );
            })
        );

        const renderActionItemSuggestions = () => (
            actionPlanSuggestions.map((value) => {
                return (
                    <ListItemSuggestionContainer
                        suggestionItem={value}
                        key={`goal-${value.user_action_plan_suggestion_id}`}
                    />
                );

            })
        );

        return (
            <Box sx={{width: '100%', maxWidth: {sm: '100%', md: '1700px'}}}>
                <Grid2>
                    <PageHeader
                        title="Action plan"
                        subtitle="An action plan focuses on professional long term growth.
                        Define 1-3 action items and 1-3 steps for each one of them: we'll suggest improvements based on the feedback you receive."
                    />
                </Grid2>
                {
                    <List sx={{width: '100%', maxWidth: "md", textAlign: "center", bgcolor: 'background.paper'}}>
                        {renderActionItems()}
                        {renderActionItemSuggestions()}
                    </List>
                }
            </Box>
        )
    }
;
