import React, { useState } from "react";
import { Autocomplete, Typography, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";

import { TitleListEng } from "./onboarding_constants";

// https://mui.com/material-ui/react-stepper
export const RoleComponentEng = () => {
  const [title, setTitle] = useState<string | null>();

  return (
    <>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 3, textAlign: "left" }}
      >
        What is your role?
      </Typography>
      <Autocomplete
        options={TitleListEng}
        sx={{ mb: 3 }}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Job Title" />
        )}
        onChange={(event, value) => setTitle(value)}
      />
      <FormHelperText sx={{ textAlign: "center" }}>
        We use this to tailor the feedback we request and your action plan.
      </FormHelperText>
    </>
  );
};
