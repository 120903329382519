import FirebaseAuth from "./authentication/firebase/Authentication";

const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

const firebaseAuth = new FirebaseAuth();

export async function get<T>(
  path: string,
  params?: URLSearchParams
): Promise<T> {
  const token = await firebaseAuth.getIdToken();
  const url = constructUrl(path, params);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(token),
    });

    return await handleResponse<T>(response, url);
  } catch (err) {
    console.error(`Error fetching ${url}:`, err);
    throw err;
  }
}

export async function post<T>(
  path: string,
  body: unknown,
  params?: URLSearchParams
): Promise<T> {
  const token = await firebaseAuth.getIdToken();
  const url = constructUrl(path, params);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(body),
    });

    return await handleResponse<T>(response, url);
  } catch (err) {
    console.error(`Error posting to ${url}:`, err);
    throw err;
  }
}

export async function put<T>(
  path: string,
  body: unknown,
  params?: URLSearchParams
): Promise<T> {
  const token = await firebaseAuth.getIdToken();
  const url = constructUrl(path, params);

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(token),
      body: JSON.stringify(body),
    });

    return await handleResponse<T>(response, url);
  } catch (err) {
    console.error(`Error putting to ${url}:`, err);
    throw err;
  }
}

function constructUrl(path: string, params?: URLSearchParams): string {
  const baseUrl = `${PUBLIC_API_URL}${path}`;
  return params ? `${baseUrl}${params.toString()}` : baseUrl;
}

function getHeaders(token: string | null): HeadersInit {
  const headers: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

async function handleResponse<T>(response: Response, url: string): Promise<T> {
  if (!response.ok) {
    const errorText = await response.text().catch(() => "Unknown error");
    throw new Error(
      `API request to ${url} failed with status ${response.status}: ${errorText}`
    );
  }

  if (response.status === 204) {
    return undefined as T;
  }

  return (await response.json()) as T;
}
