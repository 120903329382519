const PUBLIC_API_URL = process.env.REACT_APP_API_ROOT;

type UpdateActionPlanStep = {
    user_action_plan_step_id: number | null;
    user_action_plan_step_description: string;
    user_action_plan_step_measurement: string;
    user_action_plan_step_target_date: string;
};

type UpdateActionPlan = {
    user_action_plan_id: number;
    user_action_plan_priority: number;
    user_action_plan_goal: string;
    user_action_plan_business_value: string;
    user_action_plan_date_completed: string | null;
    user_action_plan_steps: Array<UpdateActionPlanStep>;
};

export const getActionPlan = async (userId: number) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans?` +
            new URLSearchParams({
                user_id: userId.toString(),
            }).toString(),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const createActionPlan = async (
    userId: number,
    priority: number,
    goal: string,
    businessValue: string,
    dateCreated: string,
    dateCompleted: string | null
) => {
    try {
        dateCompleted = dateCompleted === "" ? null : dateCompleted;
        const actionPlanBody = {
            user_id: userId,
            user_action_plan_priority: priority,
            user_action_plan_goal: goal,
            user_action_plan_business_value: businessValue,
            user_action_plan_date_created: dateCreated,
            user_action_plan_date_completed: dateCompleted,
        };
        const response = await fetch(`${PUBLIC_API_URL}/user_action_plans`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(actionPlanBody),
        });
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const updateActionPlan = async (actionPlan: ActionItemInfo) => {
    try {
        const actionPlanBody: UpdateActionPlan = {
            user_action_plan_id: actionPlan.user_action_plan_id,
            user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
            user_action_plan_goal: actionPlan.user_action_plan_goal,
            user_action_plan_business_value: actionPlan.user_action_plan_business_value,
            user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
            user_action_plan_steps: actionPlan.user_action_plan_steps || [],
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id?` +
            new URLSearchParams({
                plan_id: actionPlan.user_action_plan_id.toString(),
            }).toString(),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...actionPlanBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const updateActionPlanAggregate = async (actionPlan: ActionItemInfo) => {
    try {
        const actionPlanBody: UpdateActionPlan = {
            user_action_plan_id: actionPlan.user_action_plan_id,
            user_action_plan_priority: Number(actionPlan.user_action_plan_priority),
            user_action_plan_goal: actionPlan.user_action_plan_goal,
            user_action_plan_business_value: actionPlan.user_action_plan_business_value,
            user_action_plan_date_completed: actionPlan.user_action_plan_date_completed || null,
            user_action_plan_steps: actionPlan.user_action_plan_steps || [],
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/${actionPlan.user_action_plan_id}`,
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...actionPlanBody}),
            }
        );
        return response.status;
    } catch (err) {
        console.error(err);
    }
};

export const createActionPlanStep = async (
    planId: number,
    description: string,
    measurement: string,
    targetDate: string,
    dateCompleted: string | null
) => {
    try {
        const stepBody = {
            user_action_plan_step_description: description,
            user_action_plan_step_measurement: measurement,
            user_action_plan_step_target_date: targetDate,
            user_action_plan_step_date_completed:
                dateCompleted === "" ? null : dateCompleted,
        };
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id/steps?` +
            new URLSearchParams({
                plan_id: planId.toString(),
            }).toString(),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(stepBody),
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const getActionPlanStep = async (planId: number, stepId: number) => {
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id/steps/step_id?` +
            new URLSearchParams({
                plan_id: planId.toString(),
                step_id: stepId.toString(),
            }).toString(),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const updateActionPlanStep = async (
    planId: number,
    stepId: number,
    description: string,
    measurement: string,
    targetDate: string,
    dateCompleted: string | null
) => {
    const stepBody = {
        user_action_plan_step_description: description,
        user_action_plan_step_measurement: measurement,
        user_action_plan_step_target_date: targetDate,
        user_action_plan_step_date_completed:
            dateCompleted === "" ? null : dateCompleted,
    };
    try {
        const response = await fetch(
            `${PUBLIC_API_URL}/user_action_plans/plan_id/steps/step_id?` +
            new URLSearchParams({
                plan_id: planId.toString(),
                step_id: stepId.toString(),
            }).toString(),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(stepBody),
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};
