import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

interface AlertCardProps {
  title?: string;
  offer?: string;
  cta?: string;
  route?: string;
}

export default function AlertCard({title, offer, cta, route}: AlertCardProps) {
  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      <CardContent>
          <AutoAwesomeRoundedIcon fontSize="small" />
          {title && 
            <Typography gutterBottom sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
          }
          {offer &&
            <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
              {offer}
            </Typography>
          }
          {cta && route && 
            <Button variant="contained" size="small" fullWidth href={route}>
              {cta}
            </Button>
          }
      </CardContent>
    </Card>
  );
}