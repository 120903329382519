type Role = {
  role: string;
  function: string;
};

export const FunctionsList = [
  "Data",
  "Engineering",
  "Finance",
  "Human Resources",
  "Marketing",
  "Product Design",
  "Product Management",
  "Sales",
  "Support",
];

export const RolesList: Role[] = [
  // Role,Function
  { role: "Data Analytics", function: "Data" },
  { role: "Data Science", function: "Data" },
  { role: "Data Engineer", function: "Engineering" },
  { role: "Developer Experience / DevOps", function: "Engineering" },
  { role: "Infrastructure Engineer", function: "Engineering" },
  { role: "Machine Learning Engineer", function: "Engineering" },
  { role: "Product Engineer", function: "Engineering" },
  { role: "Quality Assurance", function: "Engineering" },
  { role: "Test Engineer", function: "Engineering" },
  { role: "Accounts Payable", function: "Finance" },
  { role: "Accounts Receivable", function: "Finance" },
  { role: "Financial Analyst", function: "Finance" },
  { role: "HR Business Partner", function: "Human Resources" },
  {
    role: "HR Generalist / Specialist / Coordinator",
    function: "Human Resources",
  },
  { role: "Recruiter", function: "Human Resources" },
  { role: "Content Creator", function: "Marketing" },
  { role: "Event Marketing", function: "Marketing" },
  { role: "Marketing Specialist", function: "Marketing" },
  { role: "Public Relations", function: "Marketing" },
  { role: "SEO", function: "Marketing" },
  { role: "Product Designer", function: "Product Design" },
  { role: "UX Research", function: "Product Design" },
  { role: "UX Writer", function: "Product Design" },
  { role: "Product Manager", function: "Product Management" },
  { role: "Product Operations", function: "Product Management" },
  { role: "Account Executive", function: "Sales" },
  { role: "Business Development Representative", function: "Sales" },
  { role: "Pre-Sales Engineer", function: "Sales" },
  { role: "Sales Operations", function: "Sales" },
  { role: "Sales Representative", function: "Sales" },
  { role: "Customer Service / Customer Support", function: "Support" },
  { role: "Customer Success", function: "Support" },
];

export const LevelsList = [
  "Entry level IC",
  "Co-op",
  "Intermediate IC",
  "Senior IC",
  "Lead",
  "Manager / Staff",
  "Director",
  "VP",
  "C-Suite",
];

export const TitleList = [
  { title: "Co-op/Intern Engineer", function: "Engineering" },
  { title: "Entry Level Engineer", function: "Engineering" },
  { title: "Intermediate Level Engineer", function: "Engineering" },
  { title: "Engineering Lead", function: "Engineering" },
  { title: "Senior Engineer", function: "Engineering" },
  { title: "Engineering Manager", function: "Engineering" },
  { title: "Sr. Manager, Engineering", function: "Engineering" },
  { title: "Staff Engineer", function: "Engineering" },
  { title: "Engineering Chief of Staff", function: "Engineering" },
  { title: "Engineering Director", function: "Engineering" },
  { title: "Principal Engineer", function: "Engineering" },
  { title: "SVP, Engineering", function: "Engineering" },
  { title: "VP, Engineering", function: "Engineering" },
  { title: "CTO", function: "Engineering" },
  { title: "Associate PM", function: "Product Management" },
  { title: "Entry level PM", function: "Product Management" },
  { title: "Entry Level PM", function: "Product Management" },
  { title: "Intermediate PM", function: "Product Management" },
  { title: "Senior PM", function: "Product Management" },
  { title: "Group Product Lead", function: "Product Management" },
  { title: "Staff PM", function: "Product Management" },
  { title: "Principal PM", function: "Product Management" },
  { title: "Product Management Director", function: "Product Management" },
  { title: "SVP, Product Management", function: "Product Management" },
  { title: "VP, Product Management", function: "Product Management" },
  { title: "CPO", function: "Product Management" },
];

export const TitleListEng = [
  "Entry Level Engineer",
  "Intermediate Level Engineer",
  "Senior Engineer",
  "Engineering Lead",
  "Engineering Manager",
  "Staff Engineer",
  "Engineering Director",
  "Principal Engineer", 
  "VP, Engineering", 
  "SVP, Engineering",
  "CTO", 
];
