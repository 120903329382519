import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getTeamGoals, GOAL_STATUS_ACTIVE } from "../../services/TeamGoalsPublicAPI";
import { PageHeader } from "../_PageHeader";
import { Box, Button, Chip, FormControlLabel, Grid2, TextField } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import Switch from '@mui/material/Switch';

import { convertDateToShort } from "../../utils/monthNames";
import { User, getCurrentUser } from "../../services/PublicAPI";

function TeamGoalsPage() {
    const [user, setUser] = React.useState<User>();
    const [goals, setGoals] = useState<TeamGoalInfo[]>([]);
  const [orgId, setOrgId] = useState<number>(9);
  const [viewLevelDetails, setViewLevel] = useState<boolean>(false);
  const [viewActiveOnly, setViewStatus] = useState<boolean>(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
        if (currentUser.org_id) {
            setOrgId(currentUser.org_id);
            fetchGoals(currentUser.org_id, viewActiveOnly);
        }
          } catch (err) {
        console.error("Failed to retrieve user. ", err);
      }
    };
    fetchUser();
  }, []);

  const fetchGoals = async (org_id: number, viewActiveOnly: boolean) => {
    var filterStatus = "";
    if (viewActiveOnly) {
        filterStatus = GOAL_STATUS_ACTIVE;
    }
    return await getTeamGoals(org_id, filterStatus).then((value) => {
      setGoals(value);
    });
  };

  const buttonClick = async () => {
    await fetchGoals(orgId, viewActiveOnly);
  };
  const viewLevelChange = async () => {
    setViewLevel(!viewLevelDetails);
  };
  const viewStatusChange = async () => {
    setViewStatus(!viewActiveOnly);
    fetchGoals(orgId, !viewActiveOnly);
  };


  const chipStyle = {
    border: '1px solid grey',
    };
  const chipStyleSuccess = {
    border: '1px solid success',
    };

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Grid2>
        <PageHeader title="Team goals"
        subtitle="This is what your team is actively working on.
        Focus on the most important goal and 1-3 indicators of success.
        Add more goals as you get close to completion: your impact on these goals will be part of your feedback."
      />
      </Grid2>
        <Grid2 container sx={{ my: 2 }} justifyContent="flex-end">
            {goals.length > 0  && 
                <>
                    <FormControlLabel label="active only" control={<Switch defaultChecked onClick={viewStatusChange} />}/>
                    {viewLevelDetails ? (
                        <Button color="info" onClick={viewLevelChange} >
                            <ZoomOutOutlinedIcon />
                        </Button>
                    ) :(
                        <Button color="info" onClick={viewLevelChange} >
                            <ZoomInOutlinedIcon />
                        </Button>
                    )}
                </>
            }
            <Button variant="contained" onClick={buttonClick}>+ New Goal</Button>
        </Grid2>
      <Grid2>
        {goals.map((goal) => (
            <Accordion key={goal.goal_id} defaultExpanded >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                    {goal.goal_achieved_date ? (
                                <Button color="success" >
                                    <CheckCircleOutlineIcon />
                                </Button>
                            ) : (
                                <Button color="info" >
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                </Button>
                            )}
                    {goal.goal_description} {}
                    {goal.goal_achieved_date ? (
                                <Button color="error" >
                                    <Chip color="primary" variant="outlined"
                                        label={`achieved ${convertDateToShort(goal.goal_achieved_date)}`}
                                        size="small"
                                        style={chipStyleSuccess}
                                        sx={{marginBottom: 0}}/>
                                </Button>
                            ) : (
                                <Button color="info" >
                                    <EditOutlinedIcon />
                                </Button>
                            )}
                    {viewLevelDetails && 
                        <Grid2 container justifyContent="left">
                            <Chip color="primary" variant="outlined"
                                label={`by ${convertDateToShort(goal.goal_target_date)}`}
                                size="small"
                                style={chipStyle}
                                sx={{marginBottom: 0}}/>
                            <Typography>
                                <i> status: {goal.goal_status}, created by {goal.goal_created_user_id} on: {convertDateToShort(goal.goal_created_date)}</i>
                            </Typography>
                        </Grid2>
                    }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <TableContainer>
                <Table>
                    {viewLevelDetails &&  
                        <TableHead>
                        <TableRow>
                            <TableCell size="small">
                            </TableCell>
                            <TableCell align="left">KPI Name</TableCell>
                            <TableCell align="left">Measure Definition</TableCell>
                            <TableCell align="center">Target Date</TableCell>
                            <TableCell align="right">Target Value</TableCell>
                        </TableRow>
                        </TableHead>
                    }
                    <TableBody>
                    {goal.goal_kpis && goal.goal_kpis.map((kpi) => (
                    viewLevelDetails ? (  
                        <TableRow key={kpi.kpi_id}>
                            <TableCell size="small">
                                {kpi.kpi_achieved_date ? (
                                    <Button color="success" >
                                        <CheckCircleOutlineIcon />
                                    </Button>
                                ) : (
                                    <Button color="info" >
                                        <CheckBoxOutlineBlankOutlinedIcon />
                                    </Button>
                                )}
                            </TableCell>
                            <TableCell align="left">{kpi.kpi_name}</TableCell>
                            <TableCell align="left">{kpi.kpi_measure_definition}</TableCell>
                            <TableCell align="center">{convertDateToShort(kpi.kpi_target_date)}</TableCell>
                            <TableCell align="right">{kpi.kpi_target_value}</TableCell>
                        </TableRow>
                        ) : (
                            <TableRow key={kpi.kpi_id}>
                            <TableCell size="small" width="5%">
                                {kpi.kpi_achieved_date ? (
                                    <Button color="success" >
                                        <CheckCircleOutlineIcon />
                                    </Button>
                                ) : (
                                    <Button color="info" >
                                        <CheckBoxOutlineBlankOutlinedIcon />
                                    </Button>
                                )}
                            </TableCell>
                            <TableCell align="left">{kpi.kpi_name} {}
                                {kpi.kpi_achieved_date ? (
                                    <Button color="error" >
                                        <Chip color="primary" variant="outlined"
                                        label={`achieved ${convertDateToShort(kpi.kpi_achieved_date)}`}
                                        size="small"
                                        style={chipStyleSuccess}
                                        sx={{marginBottom: 0}}/>
                                    </Button>
                                ) : (
                                    <Chip color="primary" variant="outlined"
                                    label={`by ${convertDateToShort(kpi.kpi_target_date)}`}
                                    size="small"
                                    style={chipStyle}
                                    sx={{marginBottom: 0}}/>
                                )}
                            </TableCell>
                        </TableRow>
                        )
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </AccordionDetails>
            </Accordion>
        ))}
        </Grid2>
        {/* 
        <Grid2 container sx={{ my: 3 }} justifyContent="center">
            <TextField
            onChange={(event) => setOrgId(Number(event.target.value))}
            type="number"
            label="Enter the Org ID"
            defaultValue={9}
            />
            <Button variant="outlined" onClick={buttonClick}>Get Goals</Button>
        </Grid2>
        */}
    </Box>
  );
}

export default TeamGoalsPage;