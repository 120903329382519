import React from "react";
import {Checkbox, Chip, TableCell, TableRow,} from "@mui/material";
import {convertDateToShort} from "../../utils/monthNames";

interface StepProps {
    stepInfo: ActionItemStepInfo;
    onStepCompleted: (stepId: number, isCompleted: boolean) => void;
}

export const StepCard = ({stepInfo, onStepCompleted}: StepProps) => {
    async function handleStepCompleted(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked; // Get the current value of the checkbox
        onStepCompleted(stepInfo.user_action_plan_step_id, isChecked);
        // TODO: handle step completion
    }

    return (
        <TableRow>
            <TableCell size="small" width="5%">
                <Checkbox checked={!!stepInfo.user_action_plan_step_date_completed} onChange={handleStepCompleted}/>
            </TableCell>
            <TableCell align="left">
                {stepInfo.user_action_plan_step_description}
                <Chip color="primary" variant="outlined"
                      label={`by ${convertDateToShort(stepInfo.user_action_plan_step_target_date)}`}
                      size="small"
                      sx={{marginLeft: 1}}/>
            </TableCell>
        </TableRow>
    );
};